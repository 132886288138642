import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CostsComponent } from './costs/costs.component';
import { AboutComponent } from './about/about.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderModule } from 'ngx-order-pipe';
import { EboutiqueComponent } from './eboutique/eboutique.component';
import { SandboxComponent } from './sandbox/sandbox.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { TestsComponent } from './tests/tests.component';


@NgModule({
  declarations: [
    AppComponent,
    CostsComponent,
    AboutComponent,
    NavbarComponent,
    FooterComponent,
    DashboardComponent,
    EboutiqueComponent,
    SandboxComponent,
    TestsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    OrderModule,
    AngularFontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
