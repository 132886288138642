import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.css']
})
export class TestsComponent implements OnInit {

  constructor(private http: HttpClient) { }

  private s3url: string = "https://s3.eu-west-3.amazonaws.com/infra-dashboard/";
  private rateUrl: string = "https://api.exchangeratesapi.io/latest?base=USD&symbols=USD,"
  private cost: Object;
  ngOnInit() {
    this.http.get(this.s3url+'cost.json')
      .subscribe(resp => {
        var result = [], key;
        for (key in resp) {
          if(resp[key].Name.indexOf('La Poste - Test') != -1) {
            resp[key]['account_id'] = key;
            resp[key]['id'] = resp[key].Name.indexOf('La Poste - Test') != -1 ? parseInt(resp[key].Name.substr(15)) : parseInt(resp[key].Name.substr(14));
            result.push(resp[key]);
          }
        }
        this.cost = result.sort(function(a, b) {
          return a['id'] - b['id'];
        });
        for (var env in this.cost) {
          this.checkEboutique(env);
        }
        this.getEboutiqueEC2RunningCount(this.cost)
        this.getEboutiqueEC2StoppedCount(this.cost)
        this.getEboutiqueRDSAvailableCount(this.cost)
        this.getEboutiqueRDSStoppedCount(this.cost)
        this.getEboutiqueTagsProject(this.cost)
        this.getEboutiqueEC2RunningStatus(this.cost)
        this.getEboutiqueEC2StoppedStatus(this.cost)
        this.getEboutiqueRDSAvailableStatus(this.cost)
        this.getEboutiqueRDSStoppedStatus(this.cost)
        this.rate(this.cost,'EUR')
      });
  }

  rate(envs,currency) {
    var rate : Number;
    this.http.get(this.rateUrl + currency)
    .subscribe(resp => {
      rate = resp['rates'].EUR;
      this.getCostYearly(envs,rate);
      this.updateActualCost(envs,rate)
    });
  }
  updateActualCost(envs,rate) {
    for (var env in envs) {
      this.cost[env].Cost = this.cost[env].Cost * rate;
    }
  }
  getCostYearly(envs,rate) {
    this.http.get(this.s3url+'cost-yearly.json')
    .subscribe(resp => {
        for (var env in envs) {
        if (resp[envs[env].account_id] && resp[envs[env].account_id] != "") {
            this.cost[env].Cost_yearly = ( resp[envs[env].account_id]['Cost'] * rate )
            }
        }
      });
  }
  getEboutiqueTagsProject(envs) {
    this.http.get(this.s3url+'tags-project.json')
    .subscribe(resp => {

        for (var env in envs) {
        if (resp[envs[env].account_id] && resp[envs[env].account_id] != "") {
            this.cost[env].tags_project = resp[envs[env].account_id]
            }
            //else this.cost[env].tags_project = "==disponible=="
        }

      });
  }
  getEboutiqueEC2RunningStatus(envs) {
    this.http.get(this.s3url+'ec2-running-status.json')
      .subscribe(resp => {
        for (var env in envs) {
          if (resp[envs[env].account_id] && resp[envs[env].account_id] > 0) {
            this.cost[env].ec2_running = true
          }
        }
      });
  }
  getEboutiqueEC2StoppedStatus(envs) {
    this.http.get(this.s3url+'ec2-stopped-status.json')
      .subscribe(resp => {
        for (var env in envs) {
          if (resp[envs[env].account_id] && resp[envs[env].account_id] > 0) {
            this.cost[env].ec2_stopped = true
          }
        }
      });
  }
  getEboutiqueEC2RunningCount(envs) {
    this.http.get(this.s3url+'ec2-running-status.json')
      .subscribe(resp => {
        for (var env in envs) {
          if (resp[envs[env].account_id]) {
            this.cost[env].ec2_running_count = resp[envs[env].account_id]
          }
        }
      });
  }
  getEboutiqueEC2StoppedCount(envs) {
    this.http.get(this.s3url+'ec2-stopped-status.json')
      .subscribe(resp => {
        for (var env in envs) {
          if (resp[envs[env].account_id]) {
            this.cost[env].ec2_stopped_count = resp[envs[env].account_id]
          }
        }
      });
  }
  getEboutiqueRDSAvailableStatus(envs) {
    this.http.get(this.s3url+'rds-available-status.json')
      .subscribe(resp => {
        for (var env in envs) {
          if (resp[envs[env].account_id] && resp[envs[env].account_id] > 0) {
            this.cost[env].rds_available = true
          }
        }
      });
  }
  getEboutiqueRDSStoppedStatus(envs) {
    this.http.get(this.s3url+'rds-stopped-status.json')
      .subscribe(resp => {
        for (var env in envs) {
          if (resp[envs[env].account_id] && resp[envs[env].account_id] > 0) {
            this.cost[env].rds_stopped = true
          }
        }
      });
  }
  getEboutiqueRDSAvailableCount(envs) {
    this.http.get(this.s3url+'rds-available-status.json')
      .subscribe(resp => {
        for (var env in envs) {
          if (resp[envs[env].account_id]) {
            this.cost[env].rds_available_count = resp[envs[env].account_id]
          }
        }
      });
  }
  getEboutiqueRDSStoppedCount(envs) {
    this.http.get(this.s3url+'rds-stopped-status.json')
      .subscribe(resp => {
        for (var env in envs) {
          if (resp[envs[env].account_id]) {
            this.cost[env].rds_stopped_count = resp[envs[env].account_id]
          }
        }
      });
  }
  checkEboutique(env) {
    this.http.get('https://dpart-' + this.cost[env].Name.substr(11) + '-eboutique.laposte.cloud/').subscribe(
      data => {},
      error => {this.cost[env].status = (error.status == 200);}
    )
  }
}

