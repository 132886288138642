import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CostsComponent } from './costs/costs.component';
import { EboutiqueComponent } from './eboutique/eboutique.component';
import { TestsComponent } from './tests/tests.component';
import { SandboxComponent } from './sandbox/sandbox.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, data: { breadcrumb: "Dashboard" } },
  { path: 'costs', component: CostsComponent, data: { breadcrumb: "Costs" } },
  { path: 'eboutique', component: EboutiqueComponent, data: { breadcrumb: "Eboutique" } },
  { path: 'sandbox', component: SandboxComponent, data: { breadcrumb: "Sandbox" } },
  { path: 'tests', component: TestsComponent, data: { breadcrumb: "Tests" } },
  { path: '', redirectTo: '/costs', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
