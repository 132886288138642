import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }
  private currentUrl = ""
  private curPage = ""
  private curUrl = ""
  private collapse = "open"

  ngOnInit() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd ) {
        this.currentUrl = e.url;
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.collapse = params['collapse'];
      });
      /*
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(event => {
      this.curPage = this.router.config.filter(
          page => page.path === event.urlAfterRedirects.substr(1))[0].data.breadcrumb;
      this.curUrl = event.urlAfterRedirects;
    });
    */
  }
}
