import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.css']
})
export class SandboxComponent implements OnInit {

  constructor(private http: HttpClient) { }

  private s3url: string = "https://s3.eu-west-3.amazonaws.com/infra-dashboard/";
  private rateUrl: string = "https://api.exchangeratesapi.io/latest?base=USD&symbols=USD,"
  private cost: Object;
  ngOnInit() {
    this.http.get(this.s3url+'cost.json')
      .subscribe(resp => {
        var result = [], key;
        for (key in resp) {
          if(resp[key].Name.indexOf('SANDBOX') != -1) {
            resp[key]['account_id'] = key;
            result.push(resp[key]);
          }
        }
        this.cost = result;
        this.rate(this.cost,'EUR')
      });
  }

  rate(envs,currency) {
    var rate : Number;
    this.http.get(this.rateUrl + currency)
    .subscribe(resp => {
      rate = resp['rates'].EUR;
      this.getCostYearly(envs,rate);
      this.updateActualCost(envs,rate)
    });
  }
  updateActualCost(envs,rate) {
    for (var env in envs) {
      this.cost[env].Cost = this.cost[env].Cost * rate;
    }
  }
  getCostYearly(envs,rate) {
    this.http.get(this.s3url+'cost-yearly.json')
    .subscribe(resp => {
        for (var env in envs) {
        if (resp[envs[env].account_id] && resp[envs[env].account_id] != "") {
            this.cost[env].Cost_yearly = ( resp[envs[env].account_id]['Cost'] * rate )
            }
        }
      });
  }
}



